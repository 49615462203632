import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {auth} from './firebase';
import { setDoc, doc} from 'firebase/firestore';
import {db} from './firebase';

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const LoginForm = styled.form`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #444;
  }
`;

function CompleteSignup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  useEffect(() => {
    // Check if the user is already authenticated
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        doneSignUp();
      } else if (user && !user.emailVerified){
        setMessage("Email is not Verified - please check your email for the verification link");
      } else {
        localStorage.clear();
        navigate('/');
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const doneSignUp = async () => {
    //e.preventDefault();
    //how do I get the email id user entered?
    //If user exists, then go to handleLogin
    if(auth.currentUser.uid && auth.currentUser.emailVerified) {
      //Create an empty document in the wishlist collection with ID = auth.user
      const data = {income: 0, 
        userlist:[],
        tasks : [],
        savings_goal:0,
        savings : 0 };

      // Add a new document in collection "wishlist" with "auth.currUser.uid" as id
      console.log("Create wishlist document with id", auth.currentUser.uid);
      try {
        await setDoc(doc(db, 'wishlist', auth.currentUser.uid), data);
        navigate('/dashboard'); //create a onboarding page
      } catch(error) {
      console.error("No user!!")
      }
    } else {
      navigate('/');
    }
  }

  return (
    <AppContainer>
        <h2>{message}</h2>
    </AppContainer>
  );
}

export default CompleteSignup;
