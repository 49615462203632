import React , { useState , useEffect} from 'react';
import styled from 'styled-components';
import { useNavigate , Link} from 'react-router-dom';
import {auth} from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import {getDoc, doc} from 'firebase/firestore';
import {db} from './firebase';

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  width: 80%;
`;

const Card = styled.div`
  background-color: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
`;

const Income = styled.div`
  font-size: 2rem;
`;

const SavingsCalculator = styled.div`
width: 300px;
  margin: 20px auto;
  text-align: center;
`;

const ProgressBarBackground = styled.div`
  height: 30px;
  width: 75%;
  background-color: #e0e0df;
  border-radius: 5px;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: green;
  transition: width 0.5s ease-in-out;
  width: ${(props) => props.percentage}%;
`;

const Wishlist = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WishlistItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0.5rem 0;
`;

const WaysToEarnMore = styled.div``;

function Dashboard() {
  const navigate = useNavigate();
  const [wishlist, setwishlist] = useState([]);
  const [goal, setGoal] = useState(0);
  const [savings, setSaving] = useState(0);
  const [income, setIncome] = useState(0);
  const [currUser, setCurrUser] = useState(auth.currentUser);
  const [isAuthenticated, setIsAuthenticated] = useState();

  useEffect(()=> {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.emailVerified) {
        // User is signed in
        setIsAuthenticated(true);
        setCurrUser(user);
      } else if (user && !user.emailVerified){
        setIsAuthenticated(false);
        alert("Please check your email and follow the directions in the veriflication email");
        navigate('/');
      } else{
        // User is signed out
        setIsAuthenticated(false);
        navigate('/');
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
    }, [navigate]);


  useEffect(()=> {
    const getWishList = async () => {
      try {
        console.log("Query database for wishlist from user:", currUser.uid);
       const wishDocRef =  doc(db,'wishlist', currUser.uid);
       const wishSnapshot = await getDoc(wishDocRef);
       if(wishSnapshot.exists()) {
        const wishDoc = wishSnapshot.data();
        console.log("Get doc with id:", wishSnapshot.id );
        setwishlist(wishDoc.userlist);
        setGoal(wishDoc.savings_goal);
        setSaving(wishDoc.savings);
        setIncome(wishDoc.income);
        console.log("wishlist:", wishDoc.userlist);
        localStorage.setItem('wishlist', JSON.stringify(wishDoc.userlist));
        localStorage.setItem('goal', JSON.stringify(wishDoc.savings_goal));
        localStorage.setItem('savings', JSON.stringify(wishDoc.savings));
        localStorage.setItem('income', JSON.stringify(wishDoc.income));
        localStorage.setItem('tasks', JSON.stringify(wishDoc.tasks));
       } else {
        console.log("No such document!!");
       }            
      } catch (error) {
        console.error('Error fetching wishlist data',error);
      }
    };
    getWishList();
  }, [navigate, currUser]);


  if (!currUser) {
      console.log("User not found");
      return (
        <DashboardContainer>
        <h3>You are logged out. Please Login again</h3>
        <Link to="/">Login</Link>
        </DashboardContainer>
      );
    } else {
      // Calculate percentage and handle case where goal is 0 to avoid division by zero
      const percentage = goal === 0 ? 0 : Math.min((savings / goal) * 100, 100);
    
      return (
      <DashboardContainer>
        <Title>Welcome to BUD-jit! Here is your portfolio!</Title>
        <GridContainer>
          <Card onClick={() => navigate('/edit-income')}>
            <h3>Monthly Income</h3>
            <Income>${income}</Income>
          </Card>
          <Card onClick={() => navigate('/savings-calculator')}>
            <h3>Savings Calculator</h3>
            <SavingsCalculator>
              <p>Your savings plan</p>
              <ProgressBarBackground>
                <ProgressBarFill percentage={percentage} />
              </ProgressBarBackground>
                <p>{percentage}% complete</p>
            </SavingsCalculator>
          </Card>
          <Card onClick={() => navigate('/wishlist')}>
            <h3>Wishlist</h3>
            <p>Add your Wishlist here</p>
            <Wishlist>
              {wishlist.map((item, index) => (
                <WishlistItem key={index}>
                  <span>{item.name}</span>
                  <span>{item.price}</span>
                </WishlistItem>
              ))}
            </Wishlist>
          </Card>
          <Card>
            <h3>Ways to earn more</h3>
            <WaysToEarnMore>
              <ul>
                <li>Walk dogs</li>
                <li>Babysit</li>
                <li>Take trash cans in/out</li>
                <li>Coach a sport (1 on 1 lesson)</li>
              </ul>
              <Link to="/blog">Read More!</Link>
            </WaysToEarnMore>
          </Card>
        </GridContainer>
      </DashboardContainer>
      ); //return
    }
  
}

export default Dashboard;
