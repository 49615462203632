import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { useLocation,  useNavigate } from 'react-router-dom';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';

const SavingsResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const Instructions = styled.ul`
  list-style: none;
  padding: 0;
`;

const InstructionItem = styled.li`
  margin-bottom: 1rem;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

function SavingsResult() {
  const location = useLocation();
  const { savingsPerDay, daysDiff, selectedItem } = location.state || {};
  const [ goal, setGoal] = useState(JSON.parse(localStorage.getItem('goal')) || 0);
  const income = JSON.parse(localStorage.getItem('income')) || '';
  const incomePerDay = (income/30).toFixed(2);
  const [ doUpdate, setDoUpdate]= useState(false);
  const user = auth.currentUser; 
 const navigate = useNavigate();

  useEffect(() => {
    const updateGoal = async() => {
      try{
        console.log("Update database for goal from user:", user.uid);
        const DocRef =  doc(db,'wishlist', user.uid);
        setDoc(DocRef, {savings_goal: goal}, {merge:true});
      } catch (error) {
        console.error('Error updating goals data',error);
      }
    };
    updateGoal(); //write to firestore for this user
  }, [doUpdate]);
  

  const handleGoalUpdate = () =>{
    setGoal(parseFloat(goal + (savingsPerDay*daysDiff)));
    //Update the localStorage
    localStorage.setItem('goal', JSON.stringify(parseFloat(goal+(savingsPerDay*daysDiff))));
    setDoUpdate(true); 
  };

  return (
    <SavingsResultContainer>
      <Title>Savings Calculator</Title>
      <p>Your saving Result!</p>
      {(parseFloat(savingsPerDay)<parseFloat(incomePerDay)) ? (
        <Instructions>
          <InstructionItem>You will need to save ${savingsPerDay} per day from your daily income of ${incomePerDay} for {daysDiff} days to buy {selectedItem}.</InstructionItem>
          <Button onClick={handleGoalUpdate}>Add this to your Savings Goal</Button>
          </Instructions>
      ) : (
        <Instructions>
          <InstructionItem>You will need to save ${savingsPerDay} per day for {daysDiff} days to buy {selectedItem}.</InstructionItem>
          <InstructionItem>Your income is ${incomePerDay} per day</InstructionItem>
          <InstructionItem>It is not possible to save this amount in the given timeframe.</InstructionItem>
          <Button onClick={navigate('/savings-calculator')}>Add this to your Savings Goal</Button>
        </Instructions>
      )}
    </SavingsResultContainer>
    //Add button to create a savings Goal and add to the App.
  );
}

export default SavingsResult;
