import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';
import { useNavigate, Link} from 'react-router-dom';

const WishlistContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const WishlistList = styled.ul`
  list-style: none;
  padding: 0;
`;

const WishlistItem = styled.li`
  background-color: #fff;
  color: #000;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
   align-items: center; /* Center vertically */
`;

/* General styles for the button */
const CrossButton = styled.button`
  background-color: #ff4d4d; /* Red background color */
  color: white; /* White text color */
  padding: 0.5rem;
  border: none; /* No border */
  border-radius: 50%; /* Circular button */
  width: 15px; /* Width of the button */
  height: 15px; /* Height of the button */
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  font-size: 12x; /* Font size for the cross */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
`;

const AddButton = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
  width: calc(100% - 2rem);
  max-width: 300px;
`;

const Button = styled.button`
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

function Wishlist() {
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState(JSON.parse(localStorage.getItem('wishlist'))|| []); //from Dashboard
  const [newItem, setNewItem] = useState('');
  const [newItemPrice, setNewItemPrice] = useState('');
  const [doUpdate, setDoUpdate] = useState(false);
  const user = auth.currentUser; 
  console.log("Wishlist set to :", wishlist);

  useEffect(() => {
    const updateWishList = async() => {
      try{
        console.log("Update database for wishlist from user:", user.uid);
        setWishlist(JSON.parse(localStorage.getItem('wishlist'))|| []);
        const wishDocRef =  doc(db,'wishlist', user.uid);
        setDoc(wishDocRef, {userlist: wishlist}, {merge:true});
      } catch (error) {
        console.error('Error updating wishlist data',error);
      }
    };
    if(doUpdate) {
      localStorage.setItem('wishlist', JSON.stringify(wishlist));
      updateWishList(); //write to firestore for this user
      
    } else {
      console.log("No do update");
    }
  }, [doUpdate]);
  
  const delItem = (index) => {
    const updatedList = wishlist.filter((_, i) => i!==index);
    setWishlist(updatedList);
  };

  if (user) {
    const addItem = () => {
      if (newItem.trim()) {
        setWishlist([...wishlist, {name: newItem.trim(), price: newItemPrice.trim()}]);
        setNewItem('');
        setNewItemPrice('');
        //setDoUpdate(true); 
        //console.log("Setting do update to true");
      }
    };
    return (
        <WishlistContainer>
          <Title>Wishlist</Title>
          <WishlistList>
          {wishlist.map((item, index) => (
                <WishlistItem key={index}>
                  <span>{item.name} </span>
                  <span> ${item.price}</span>
                  <CrossButton onClick={()=>delItem(index)}>X</CrossButton>
                </WishlistItem>
              ))}
          </WishlistList>
          <Input
            type="text"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            placeholder="Type item here..."
          />
          <Input
            type="number"
            value={newItemPrice}
            onChange={(e) => setNewItemPrice(e.target.value)}
            placeholder="Type item price here..."
          />
          <AddButton onClick={addItem}>+ Add an item to your wishlist</AddButton>
          <Button onClick={() => setDoUpdate(true)}>Update my Wish List</Button>
          <Link to="/dashboard">Return to Dashboard</Link>
        </WishlistContainer>
      );
  } else {
    localStorage.clear();
    return (
      navigate('/')
    );
  }
      
}

export default Wishlist;
