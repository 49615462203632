import React from 'react';
import './Footer.css';

function Footer() {
    return(
      <footer>
        Thanks for using BUDJIT
      </footer>
    );
}

export default Footer;