import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';

const SavingsCalculatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const Dropdown = styled.select`
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  width: calc(100% - 2rem);
  max-width: 300px;
`;

const CalculateButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #32CD32;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

function SavingsCalculator() {
  const navigate = useNavigate();
  const [wishlist,setwishList] = useState(JSON.parse(localStorage.getItem('wishlist')) || []);
  console.log("Wishlist set to :", wishlist);

  const [selectedItem, setSelectedItem] = useState('');
  const [date, setDate] = useState('');
  const [error, setError] = useState('');
  const [ savings, setSavings] = useState(JSON.parse(localStorage.getItem('savings')) || 0);
  const [ doUpdate, setDoUpdate]= useState(false);
  const user = auth.currentUser; 

  useEffect(() => {
    const updateSavings = async() => {
      try{
        console.log("Update database for savings from user:", user.uid);
        const DocRef =  doc(db,'wishlist', user.uid);
        setDoc(DocRef, {savings: savings}, {merge:true});
      } catch (error) {
        console.error('Error updating savings data',error);
      }
    };
    updateSavings(); //write to firestore for this user
  }, [doUpdate]);

  useEffect(() => {
    setwishList(JSON.parse(localStorage.getItem('wishlist')) || []);
    if (wishlist.length > 0) {
      setSelectedItem(wishlist[0].name);
    }
  }, []);

  // Get the current date in the format yyyy-mm-dd
  const getTodayDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months start at 0!
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
  };

  const handleSavingUpdate = ()=> {
    setSavings(parseFloat(savings));
    //Update the localStorage
    localStorage.setItem('savings', JSON.stringify(parseFloat(savings)));
    setDoUpdate(true); 
  };

  const handleCalculate = () => {
    if (!selectedItem || !date) {
      setError('Please select an item and enter a date.');
      return;
    }

    const selectedWishlistItem = wishlist.find(item => item.name === selectedItem);
    const targetDate = new Date(date);
    const currentDate = new Date();
    const timeDiff = (targetDate.getTime() - currentDate.getTime()<1)? 1 :(targetDate.getTime() - currentDate.getTime()) ;
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (targetDate.getTime() - currentDate.getTime()<1){
      setError('Selected a date in the future.');
      return;
    }

    const savingsPerDay = (selectedWishlistItem.price / daysDiff).toFixed(2);
    localStorage.setItem('goal', JSON.stringify(savingsPerDay));
    navigate('/savings-result', { state: { savingsPerDay, daysDiff, selectedItem } });
  };

  return (
    <SavingsCalculatorContainer>
      <Title>Savings Calculator</Title>
      <p>Need something by a specific date? We are here to help!</p>
      <Dropdown value={selectedItem} onChange={(e) => setSelectedItem(e.target.value)}>
        {wishlist.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        ))}
      </Dropdown>
      <Input
        type="date"
        value={date}
        min={getTodayDate()}
        onChange={(e) => setDate(e.target.value)}
        placeholder="Enter the date you wish to purchase the item"
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <CalculateButton onClick={handleCalculate}>calculate!</CalculateButton>

      <Input
            type="number"
            value={savings}
            onChange={(e) => setSavings(e.target.value)}
            placeholder="Type saving amount here..."
      />
      <CalculateButton onClick={handleSavingUpdate}>Update your Total savings</CalculateButton>
    </SavingsCalculatorContainer>
  );
}

export default SavingsCalculator;
