import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectAuthEmulator } from "firebase/auth";
import { connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCGax8u2VknclddC4M_r6v4l732hVfwsjo",
    authDomain: "tidy-groove-426504-p2.firebaseapp.com",
    projectId: "tidy-groove-426504-p2",
    storageBucket: "tidy-groove-426504-p2.appspot.com",
    messagingSenderId: "65501823671",
    appId: "1:65501823671:web:b518af464efdab256c8332"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

 // ADD THESE LINES
 if (window.location.hostname === "127.0.0.1" || window.location.hostname === "localhost" ) {
    console.log("127.0.0.1 detected!");
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectAuthEmulator(auth,'http://127.0.0.1:9000');
  } else {
    console.log("Not using Local Emulator");
  }