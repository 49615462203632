// Blog.js
import React from 'react';
import { Link} from 'react-router-dom';
const Blog = () => {
  return (
    <div style={{ padding: 20 }}>
      <h1>Blog: Lets Make Money</h1>
      <div style={{ margin: "20px 0", height: 300, backgroundColor: "#d3d3d3", display: "flex", justifyContent: "center", alignItems: "center" }}>
        {/*<img src="/path/to/your/comic-strip.png" alt="Comic Strip" style={{ maxHeight: '100%', maxWidth: '100%' }} />*/}
      </div>
      <h2>Ways for Kids to Make Quick Money</h2>
      <ol>
        <li><strong>Lemonade Stand:</strong> The classic lemonade stand is a great way for kids to learn about business and make some money.</li>
        <li><strong>Yard Work:</strong> Kids can offer to mow lawns, rake leaves, shovel snow, or do other yard work for neighbors.</li>
        <li><strong>Pet Sitting and Dog Walking:</strong> For kids who love animals, pet sitting and dog walking are perfect jobs.</li>
        <li><strong>Craft Sales:</strong> Kids who are crafty can make and sell their creations.</li>
        <li><strong>Car Washing:</strong> Setting up a car wash in the neighborhood can be a fun and profitable activity.</li>
        <li><strong>Babysitting:</strong> Older kids and teenagers can offer babysitting services.</li>
        <li><strong>Recycling:</strong> Kids can collect cans, bottles, and other recyclables from around the neighborhood and take them to a recycling center.</li>
        <li><strong>Tutoring:</strong> If a child excels in a particular subject, they can offer tutoring services to younger kids or peers who need help.</li>
        <li><strong>Selling Baked Goods:</strong> Kids who enjoy baking can make cookies, cupcakes, or other treats to sell.</li>
      </ol>

      <Link to="/dashboard">Back to My Dashboard</Link>
    </div>
  );
};

export default Blog;
