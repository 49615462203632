import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import {doc, setDoc} from 'firebase/firestore';
import {auth} from './firebase';
import {db} from './firebase';

const EditIncomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: #000;
  color: white;
  padding: 2rem;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
`;

const IncomeCard = styled.div`
  background-color: #fff;
  color: #000;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 2rem;
  text-align: center;
  border: none;
  border-radius: 8px;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
`;

function EditIncome() {
  const [income, setIncome] = useState(JSON.parse(localStorage.getItem('income')) || 0);
  const navigate = useNavigate();

  useEffect(() => {
  const updateIncome = async() => {
    try{
      const user = auth.currentUser; 
      console.log("Update database for income from user:", user.uid);
      const wishDocRef =  doc(db,'wishlist', user.uid);
      setDoc(wishDocRef, {income: income}, {merge:true});
      localStorage.setItem('income', JSON.stringify(income));
    } catch (error) {
      console.error('Error updating income data',error);
    }
  };
    updateIncome(); //write to firestore for this user
  }, [income]);

  return (
    <EditIncomeContainer>
      <Title>Edit your monthly income here!</Title>
      <IncomeCard>
        <h3>${income}</h3>
      </IncomeCard>
      <Button onClick={() => navigate('/log-tasks')}>Edit Income</Button>
    </EditIncomeContainer>
  );
}

export default EditIncome;
