//PwdReset.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import {auth} from './firebase';

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const PwdResetForm = styled.form`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #444;
  }
`;

function PwdReset() {
  const [email, setEmail] = useState(''); 
  const navigate = useNavigate();

  const handleReset = async (e) => {
    e.preventDefault();
    //If user exists, then go to handleLogin
  try {
    await sendPasswordResetEmail(auth, email);
    alert('Sent Password reset to your email')
    navigate('/');
  } catch (error) {
  
  }
  }

  return (
    <AppContainer>
      <PwdResetForm onSubmit={handleReset}>
        <h2>Please Enter your email</h2>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Button type="submit">Send Password Reset Email</Button>
      </PwdResetForm>
    </AppContainer>
  );
}

export default PwdReset;
