import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import {auth} from './firebase';

import Login from './Login';
import Dashboard from './Dashboard';
import EditIncome from './EditIncome';
import LogTasks from './LogTasks';
import Wishlist from './Wishlist';
import SavingsCalculator from './SavingsCalculator';
import SavingsResult from './SavingsResult';
import Blog from './Blog';
import PwdReset from './PwdReset';
import CompleteSignup from './CompleteSignup';
import Signup from './Signup';
import './App.css';

function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup/>}/>
        <Route path="/completesignup" element={<CompleteSignup/>}/>        
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="/edit-income" element={<EditIncome />} />
        <Route path="/log-tasks" element={<LogTasks />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/savings-calculator" element={<SavingsCalculator />} />
        <Route path="/savings-result" element={<SavingsResult />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/pwdReset" element={<PwdReset />} />
      </Routes>
    </Router>
  );
}

export default App;
