import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import {auth} from './firebase';
import { signInWithEmailAndPassword } from "firebase/auth";

const AppContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #000;
`;

const LoginForm = styled.form`
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;

  &:hover {
    background-color: #444;
  }
`;

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
 
  useEffect(() => {
    // Check if the user is already authenticated
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        navigate('/dashboard');
      } else if(user && !user.emailVerified){
        navigate('/')
      }else{
        localStorage.clear();
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  const handleLogin = async (e) => {
    e.preventDefault();
    //If user does not exists, then go to handleLogin
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (err) {
      console.log("Login failed: ", err);
      if(err.code == 'auth/user-not-found') {
        alert("User not found. Please click the New User Button");
        return;
      }
      else if (err.code == 'auth/invalid-email'){
        alert("Please enter a vaild email address");
        return;
      }
    }
  };

  return (
    <AppContainer>
      <LoginForm>
        <h2>Welcome to BUD-jit</h2>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <Button onClick={handleLogin}>Sign In</Button>
        <p><Link to="/signup">New User Sign Up</Link></p>
        <p><Link to="/pwdreset">  Forgot password?</Link></p>
      </LoginForm>
    </AppContainer>
  );
}

export default Login;
